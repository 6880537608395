import Vue from 'vue';
import MainHeader from '../Components/MainHeader.vue';
import { MixinUser } from '../common/MixinUser';
import { MixinDynamicFooter } from '../common/MixinDynamicFooter';

const es6Promise = require('es6-promise');

if (!global.Promise) {
  es6Promise.polyfill();
}

const v = new Vue({
  el: '#app',
  components: {
    'main-header': MainHeader,
  },
  mixins: [MixinUser, MixinDynamicFooter],
  data: {},
  computed: {},
  watch: {},
  mounted() {
  },
  methods: {},
});
