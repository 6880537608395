/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */

import Vue from 'vue';
import axios from 'axios';
import { formatFullDate } from '../util/date/format';
import { Paths } from './paths';

axios.defaults.headers.common.Authorization = `Bearer ${window.token}`;
axios.defaults.baseURL = window.ResourceService;
axios.defaults.headers.post['Content-Type'] = 'application/json';
Vue.prototype.$http = axios;

export const MixinUser = {
  data() {
    return {
      contact: {
        loaded: false,
        account: {
          idAzure: null,
          dataPenultimoAccesso: null,
          dataUltimoAccesso: null,
          cessato: false,
          abilitato: null,
        },
        profilo: {
          idProfilo: null,
          services: null,
          servicesLite: null,
          serviceList: [],
          programs: null,
          programsLite: null,
          programList: [],
        },
        personali: {
          nome: null,
          cognome: null,
          email: null,
          telefono: null,
        },
        azienda: {
          codiceMichelangelo: null,
          codiceFiscale: null,
          ragioneSociale: null,
        },
        consensi: {
          consensoCommerciale: null,
          consensoGruppo: null,
          consensoPrivacy: null,
        },
        programmi: {
          businessPromotion: {
            iscritto: false,
            preferenze: {
              dilazione: null,
              exportArea: null,
              settoreVendita: null,
              modalitaPagamento: null,
            },
            buyers: [],
          },
          saceEducation: {
            saceEduSigned: false,
            exportAndInternationalization: null,
            certifiedAndAcademicCourses: null,
            ecologicalAndDigitalTransition: null,
            events: null,
          },
        },
      },
    };
  },
  computed: {
    enabled() {
      return this.contact.account.isEnabled;
    },
    firstAcces() {
      return this.contact.account.approvazione === 99;
    },
    userWaiting() {
      return this.contact.account.approvazione === 0;
    },
    userApproved() {
      return this.contact.account.approvazione === 1 || this.contact.account.approvazione === 5;
    },
    userRejected() {
      return this.contact.account.approvazione === 2;
    },
    noRequest() {
      return this.contact.account.approvazione === 3;
    },
    approved() {
      return this.enabled && this.userApproved;
    },
    productsEnabled() {
      return this.approved && this.contact.profilo.products;
    },
    platformsEnabled() {
      return this.contact.profilo.platforms;
    },
    serviziEnabled() {
      return this.contact.profilo.services || this.contact.profilo.servicesLite;
    },
    programmiEnabled() {
      return this.contact.profilo.programs || this.contact.profilo.programsLite;
    },
    products() {
      return {
        assicurazioneEnabled: this.productsEnabled && this.contact.prodotti.assicurazione,
        vaEnabled: this.productsEnabled && this.contact.prodotti.valutazioneAzienda,
        cauzioneEnabled: this.productsEnabled && this.contact.prodotti.cauzione,
        catNatEnabled: this.productsEnabled && this.contact.prodotti.catNat.enabled,
        richiestaIndennizzoEnabled: this.productsEnabled && this.contact.prodotti.richiestaIndennizzo,
        protezioneClimaSmartEnabled: this.productsEnabled && this.contact.prodotti.protezioneClima,
        ccdAbbonamentoEnabled: this.productsEnabled && this.contact.prodotti.ccdAbbonamento,
        serviziEnabled: {
          calendarioEventi:
            this.serviziEnabled && this.contact.profilo.serviceList.calendarioEventiEnabled,
          pta: this.serviziEnabled && this.contact.profilo.serviceList.ptaEnabled,
          sanzioni: this.serviziEnabled && this.contact.profilo.serviceList.sanzioniEnabled,
          tem: this.serviziEnabled && this.contact.profilo.serviceList.temEnabled,
          espertoRisponde: this.serviziEnabled && this.contact.profilo.serviceList.espertoRispondeEnabled,
          consulenzaPersonalizzata: this.serviziEnabled && this.contact.profilo.serviceList.consulenzaPersEnabled,
          sanctionsGPS: this.serviziEnabled && this.contact.profilo.serviceList.dueDiligenceEnabled,
        },
        programmiEnabled: {
          businessPromotion: this.programmiEnabled && this.contact.profilo.programList.bpEnabled,
          saceEducation: this.programmiEnabled && this.contact.profilo.programList.eduEnabled,
        },
        platformsEnabled: {
          esg: this.platformsEnabled && this.contact.platforms.esg,
        }
      };
    },
  },
  created() {
    this.getContact();
  },
  methods: {
    getContact() {
      this.$http
        .get(`${Paths.anagrafica.getContact}`)
        .then(response => {
          this.formatResult(response.data);
        })
        .catch(error => {
          console.error('error', error.message);
        });
    },
    formatResult(data) {
      this.contact = {
        loaded: true,
        personali: {
          nome: data.nome,
          cognome: data.cognome,
          email: data.email,
          telefono: data.telefono,
        },
        account: {
          dataPenultimoAccesso: formatFullDate(data.dataPenultimoAccesso),
          dataUltimoAccesso: formatFullDate(data.dataUltimoAccesso),
          cessato: data.cessato,
          approvazione: data.waitingApproval,
          isEnabled: data.enabled,
        },
        profilo: {
          idProfilo: data.profilo.id.toString(),
          products: data.profilo.products,
          services: data.profilo.services,
          servicesLite: data.profilo.servicesLight,
          serviceList: data.profilo.servizi,
          programs: data.profilo.programs,
          programsLite: data.profilo.programsLight,
          programList: data.profilo.programmi,
          platforms: data.profilo.platforms,
        },
        azienda: {
          codiceMichelangelo: data.codiceMichelangelo,
          codiceFiscale: data.codiceFiscale,
          ragioneSociale: data.ragioneSociale,
        },
        consensi: {
          consensoCommerciale: data.consensoCommerciale,
          consensoGruppo: data.consensoGruppo,
          consensoPrivacy: data.consensoPrivacy,
        },
        prodotti: {
          assicurazione: data.prodotti.assicurazioneEnabled,
          valutazioneAzienda: data.prodotti.vaEnabled,
          cauzione: data.prodotti.cauzioneEnabled,
          richiestaIndennizzo: data.prodotti.richiestaIndenizzoEnabled,
          catNat: {
            enabled: data.prodotti.catNatEnabled,
            premium: data.profilo.id === 95,
          },
          protezioneClima: data.prodotti.protezioneClimaSmartEnabled,
          ccdAbbonamento: data.prodotti.ccdAbbonamentoEnabled,
        },
        platforms: {
          esg: data.platforms.esgEnabled,
          esgSigned: data.signedUpEsg,
          esgPartner: data.partner,
        },
        programmi: {
          businessPromotion: {
            iscritto: data.bpSigned,
            preferenze: {
              dilazione: data.contattiDilazioneId,
              exportArea: data.contattiExportAreaId,
              settoreVendita: data.contattiSettoreVenditaId,
              modalitaPagamento: data.contattiModalitaPagamentoId,
            },
            buyers: data.contattiBuyerId,
          },
          saceEducation: data.saceEducation,
        },
      };
    },
  },
};
