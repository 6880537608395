var es6Promise = require("es6-promise");
if (!global.Promise) {
  es6Promise.polyfill();
}

import Vue from "vue";
 

import dropdown from "vue-my-dropdown";
Vue.component("dropdown", dropdown);
Vue.use(dropdown);

import axios from "axios";
axios.defaults.headers.common["Authorization"] = "Bearer " + window.token;
axios.defaults.baseURL = window.ResourceService;
axios.defaults.headers.post["Content-Type"] = "application/json";
Vue.prototype.$http = axios; //use axios globally in all my components vue

import VueFormWizard from "vue-form-wizard";
Vue.use(VueFormWizard);

import VeeValidate, { Validator } from "vee-validate";
import VeeValidateMessaggesIt from "vee-validate/dist/locale/it";
Validator.localize("it", VeeValidateMessaggesIt);
Vue.use(VeeValidate, {
  locale: "it",
  dictionary: { it: { dateFormat: "DD/MM/YYYY" } },
  events: "" //disable all validation triggered by events, for example you
  // only want to validate once the user clicks the submit button
});

import Notifications from "vue-notification";
Vue.use(Notifications);

import { PtaValidationMsg } from "./validationMsg/ptaValidationMsg";

import { Utils } from "./Utils";

export const MixinPagineBase = {
  data: function() {
    return {
      /* parte comune a Home/main.js */
      privacyLink: window.PrivacyLink,
      showHelp: true,
      visible: false,
      position: ["right", "top", "right", "top"],
      /* /parte comune a Home/main.js */

      loading: false,
      loadingMsg: "",
      loadingCounter: 0,
      completed: false
    };
  },

  computed: {
    isAuthenticated: function() {
      return window.isAuthenticated === "false" ? false : true;
    }
  },

  mounted: function() {

    var self = this;

    // self.getContactLogged();
    // Add a request interceptor
    axios.interceptors.request.use(
      function(config) {
        // Do something before request is sent
        self.setLoading(true);
        return config;
      },
      function(error) {
        // Do something with request error
        self.setLoading(true);
        return Promise.reject(error);
      }
    );

    // Add a response interceptor
    axios.interceptors.response.use(
      function(response) {
        // Do something with response data
        self.setLoading(false);
        return response;
      },
      function(error) {
        // Do something with response error
        self.setLoading(false);

        self.errorMsg = error.message;

        self.$notify({
          group: "attendiElaborazione",
          clean: true
        });

        self.$notify({
          group: "erroriTecnici",
          title: "Errore generico",
          text: PtaValidationMsg.apiError
        });

        return Promise.reject(error);
      }
    );

    /* parte comune a Home/main.js */
    $("div.dialog").css({ display: "" });
    $("div.my-dropdown-dd").css({ "z-index": "1", top: "0" });
    /* /parte comune a Home/main.js */

    Utils.numberField();
  },
  methods: {
    setLoading: function(value, msg) {
      // TODO: Rifattorizzare
      if (value) {
        this.loadingCounter++;
      } else {
        this.loadingCounter--;
      }
      this.loading = this.loadingCounter > 0;
      this.loadingMsg = msg;
    },
    onError: function(errorMsg) {
      var self = this;
      if (errorMsg != null) {
        if ("validation errors" == errorMsg) {
          errorMsg = null;
          self.$notify({ group: "mex", text: "Errore di validazione" });
        } else {
          self.$notify({
            group: "mex",
            text: errorMsg
          });
        }
      }
      this.errorMsg = errorMsg;
    }
  }
};
