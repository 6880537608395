/* eslint-disable import/prefer-default-export */
export const Paths = Object.freeze({
  documentCondizioniParticolariPolizza: 'resources/docs/Credito_fornitore_CSP.pdf',

  clausoleFM: 'resources/docs/Clausole_vessatorie_FM.pdf',
  clausoleAT: 'resources/docs/Clausole_vessatorie_AT.pdf',
  clausolePA: 'resources/docs/Clausole_vessatorie_PA.pdf',
  clausoleSF: 'resources/docs/Clausole_vessatorie_SF.pdf',
  clausoleIMEU: 'resources/docs/Clausole_vessatorie_IMEU.pdf',
  clausoleEC: 'resources/docs/Clausole_vessatorie_EC.pdf',
  clausoleTE: 'resources/docs/Clausole_vessatorie_TE.pdf',

  moduloDomandaPDF: 'resources/docs/AT_ModuloDomanda.pdf',
  dichiarazioneSocietaServiziPDF: 'resources/docs/dichiarazione-societa-di-servizi.pdf',
  dichiarazioneSocietaServiziPDF_inglese: 'resources/docs/dichiarazione-societa-di-servizi_eng.pdf',
  dichiarazioneFierePDF: 'resources/docs/dichiarazione-fiere.pdf',
  dichiarazioneFierePNRR: 'resources/docs/dichiarazione-fiere-pnrr.pdf',
  docAntimafia: '/resources/docs/autocertificazione_antimafia.zip',
  saveDatiConfermaDati: '/private/prodotti/assicurazione/acquisto-polizza/save-dati-conferma-dati',
  anagrafica: {
    search: '/anagrafica/search',
    selectCompany: '/private/anagrafica/selectCompany',
    getContact: 'private/user/getContact',
    getContactPrivacy: 'private/user/getContactPrivacy',
    saveConsensi: 'private/profile/saveConsensi',
  },
  crm01: 'crmController/call-crm01',
  financial: {
    getAllCurrencies: 'financial/getAllCurrencies',
    calcoloPricingToolAvanzato: 'financial/calcoloPricingToolAvanzato',
    saveParams: 'financial/saveParams',
  },
  documents: {
    createFileCP: 'private/create-file-cp',
    getFileCP: 'private/retrieve-file-cp',
    getFilesCP: 'private/retrieve-files-cp',
  },
  getComuni: 'getComuni',
  getProvince: 'getProvince',
  genericUpload: 'private/genericUpload',
  news: 'news/getNews',
  newsCountries: 'news/getThomsonReutersCountries',
  newsTopics: 'news/getThomsonReutersTopics',
  newsDetail: 'news/getNewsUrl',
  currency: {
    getTaxRate: 'private/currency/tax-rate/',
  },
  temporaryExportManager: {
    getValues: 'private/servizi/lite/tem/values/',
    getCompanies: 'private/servizi/lite/tem/companies',
    loadDatiCompany: 'private/servizi/lite/tem/company',
    loadTems: 'private/servizi/lite/tem/tems',
  },
  calendarioEventi: {
    page: 'private/servizi/lite/calendario-eventi/eventcalendars',
    slides: 'private/servizi/lite/calendario-eventi/values/slides',
    services: 'private/servizi/lite/calendario-eventi/values/services',
    loadTopics: 'private/servizi/lite/calendario-eventi/values/topics',
    loadLocations: 'private/servizi/lite/calendario-eventi/values/locations',
    loadItalianRegions: 'private/servizi/lite/calendario-eventi/values/regions',
    loadSubjects: 'private/servizi/lite/calendario-eventi/values/subjects',
    loadEvents: 'private/servizi/lite/calendario-eventi/events',
    loadMyEvents: 'private/servizi/lite/calendario-eventi/myEvents',
    loadLocalEvent: 'private/servizi/lite/calendario-eventi/eventoDb',
    eventSignUp: 'private/servizi/lite/calendario-eventi/myEvents/signedUp',
    addToMyEvents: 'private/servizi/lite/calendario-eventi/myEvents/saved',
    cycleSignUp: 'private/servizi/lite/calendario-eventi/myEvents/signedUpCycle',
    loadEventCycle: 'private/servizi/lite/calendario-eventi/eventCycle',
    education: {
      toggleToPath: 'private/servizi/lite/calendario-eventi/education/percorsiFormativi',
      pathListOptions: 'private/servizi/lite/calendario-eventi/education/listPercorsiFormativi',
      newPah: 'private/servizi/lite/calendario-eventi/education/percorsiFormativi/saved',
    },
  },
  businessPromotion: {
    manageProfile: 'private/programmi/business-promotion/updateBP',
    localValues: 'valuesType/',
    values: 'private/programmi/lite/business-promotion/values/',
    buyer: 'private/programmi/lite/business-promotion/buyers',
    bpPage: 'private/programmi/lite/business-promotion/businesspromotions',
    toggleBuyer: 'private/programmi/business-promotion/toggleBuyer',
    loadTopics: 'private/programmi/lite/business-promotion/values/topics',
    loadEvents: 'private/programmi/lite/business-promotion/events',
  },
  registrazione: {
    profili: 'valuesType/profili',
    profiliPrivati: 'valuesType/profiliPrivati',
    companyName: '/registrati/companyName',
    getPaesi: '/valuesType/paesi',
    submit: '/registrati/savecontactriskfree',
    submitComplete: 'private/complete-profile',
  },
  preRegistrazione: {
    submit: 'private/pre-registrazione/submit',
    checkEmail: 'private/pre-registrazione/check-email',
    companyName: 'private/pre-registrazione/companyName',
  },
  saceEducation: {
    events: {
      types: 'private/programmi/lite/sace-education/events/values/topics',
      load: 'private/programmi/lite/sace-education/events',
      signUp: 'private/programmi/lite/sace-education/myEvents/signedUp',
    },
    contents: {
      types: 'private/programmi/lite/sace-education/contents/values/types',
      topics: 'private/programmi/lite/sace-education/contents/values/topics',
      load: 'private/programmi/lite/sace-education/contents',
      done: 'private/programmi/lite/sace-education/contents/done',
    },
    registration: {
      signIn: 'private/programmi/lite/sace-education/registration',
      options: 'valueTypes/EduSignInOptions',
    },
    search: 'private/programmi/lite/sace-education/search/contents',
    trainingCourses: {
      toggleContent: 'private/programmi/lite/sace-education/contents/percorsiFormativi',
      selectOptions: 'private/programmi/lite/sace-education/contents/listPercorsiFormativi',
      list: 'private/programmi/lite/sace-education/contents/percorsiFormativi',
      new: 'private/programmi/lite/sace-education/contents/percorsiFormativi/saved',
      update: 'private/programmi/lite/sace-education/contents/percorsiFormativi/update',
      delete: 'private/programmi/lite/sace-education/contents/percorsiFormativi/delete',
      updatePath: 'private/programmi/lite/sace-education/contents/percorsoPredefinito/update',
    },
  },
  favouriteContents: {
    list: 'private/favoriteContents/allContents',
    toggleContent: 'private/favoriteContents/saved',
    eventTypes: 'private/favoriteContents/events/values/topics',
    contentTypes: 'private/favoriteContents/contents/values/types',
  },
  consulenzaPersonalizzata: {
    loadConsultant: '/private/servizi/lite/consulenza-personalizzata/load-consultant',
    createTask: '/private/servizi/lite/consulenza-personalizzata/create-task',
  },
  sanctionsGPS: {
    loadConsultant: '/private/servizi/lite/sanctionsGPS/load-consultant',
    createTask: '/private/servizi/lite/sanctionsGPS/create-task',
  },
  esg: {
    signUpEsg: '/private/platform/esg/signedUpEsg',
    createTask: '/private/platform/esg/desk/task-consultant',
    loadConsultant: '/private/platform/esg/desk/load-consultant',
    loadEvents: '/private/platform/esg/events',
    eventDetail: '/private/platform/esg/event',
    loadPercorsoFormativo: '/private/platform/esg/percorsoESG',
    loadDetailEduDossier: '/private/platform/esg/education-dossier',
    loadSectors: '/private/platform/esg/future-sectors',
    saveSectors: '/private/platform/esg/future-sectors/save',
    loadSolutions: '/private/platform/esg/solutions',
    loadDetailsolution: '/private/platform/esg/solution',
    loadProjects: 'private/platform/esg/sustainable-projects',
    loadDetailProject: 'private/platform/esg/sustainable-project',
    loadOffertaPErsonalizzata: '/private/platform/esg/personalized-dossier',
    // todo aggiungere load dossier
    dossier: {
      done: 'private/programmi/lite/sace-education/education-dossier/done',
    },
    factoringTask: 'private/platform/esg/factoring-task',
    partner: {
      areaInteresse: 'private/platform/esg/values/areas-interest',
      servizi: 'private/platform/esg/values/services',
      regioni: 'private/platform/esg/values/regions',
      search: 'private/platform/esg/partners',
      profile: 'private/profile/esg-partner',
      createTask: 'private/platform/esg/partner-task ',
    },
  },
  protezioneClima: {
    getViario: 'private/prodotti/protezione-clima/viario',
    getRegioni: 'private/prodotti/protezione-clima/regioni',
    getProvince: 'private/prodotti/protezione-clima/province',
    getCitta: 'private/prodotti/protezione-clima/comuni',
    getCap: 'private/prodotti/protezione-clima/cap',
    checkCap: 'private/prodotti/protezione-clima/check/cap',
    save: 'private/prodotti/protezione-clima/save',
    getIntermediari: 'private/prodotti/protezione-clima/intermediari',
    checkout: 'private/checkout',
    getFascicoli: 'private/prodotti/protezione-clima/fascicoli',
    loadDati: '/private/prodotti/protezione-clima/load',
    createTask: 'private/prodotti/protezione-clima/desk/task-consultant',
    loadConsultant: 'private/prodotti/protezione-clima/desk/load-consultant',
  },
  ccdAbbonamento: {
    checkAbbonamento: 'private/prodotti/consultazione-plafond/check-abbonamento',
    getCoupon: 'private/prodotti/consultazione-plafond/coupon',
    getPaesi: '/valuesType/paesi',
    subscribe: 'private/prodotti/consultazione-plafond/subscribe',
    listaBanche: 'private/prodotti/consultazione-plafond/lista-banche',
    calcoloPremio: 'private/prodotti/consultazione-plafond/calcolo-premio',
    createTask: 'private/prodotti/consultazione-plafond/create-task',
    loadConsultant: 'private/prodotti/consultazione-plafond/load-consultant',
  }
});
