const StatoUtenze = {
  ATTESA: 0,
  APPROVATO: 1,
  NON_APPROVATO: 2,
  NON_RICHIESTA: 3,
  NON_APPROVABILE: 4,
  APPROVATO_AUTOMATICO: 5,
  PRIMO_ACCESSO: 99,
};

const Profili = {
  DIP_AZIENDA: '1', // Dipendente di un'azienda
  DIP_CREDITO: '2', // Dipendente di un istituto di credito
  CONS_LIB_PROF: '3', // Consulente / Libero professionista
  CONS_LIB_PROF_NO_COD_MICH: '4', // Consulente / Libero professionista senza codice michelangelo
  DIP_PA: '5', // Dipendente di una Pubblica Amministrazione
  REF_ASS: '6', // Referente di un'associazione
  REF_ASS_NO_COD_MICH: '7', //  Referente di un'associazione senza codice michelangelo
  DIP_AZIENDA_ESTERO: '8', // Dipendente di un'azienda estera
  GIORNALISTA: '9', // Giornalista
  PROF_STUDENTE: '10', // Professore o Studente
  BROKER: '11', //Broker
  ALTRO: '99', // Non appartengo a nessuna delle categorie precedenti
};

export const Utenze = {
  ATTESA: { value: StatoUtenze.ATTESA, label: 'In attesa' },
  APPROVATO: { value: StatoUtenze.APPROVATO, label: 'Approvato' },
  NON_APPROVATO: {
    value: StatoUtenze.NON_APPROVATO,
    label: 'Rifiutato',
  },
  NON_RICHIESTA: {
    value: StatoUtenze.NON_RICHIESTA,
    label: 'Abilitazione non richiesta',
  },
  NON_APPROVABILE: {
    value: StatoUtenze.NON_APPROVABILE,
    label: 'Abilitazione prodotti non prevista',
  },
  PRIMO_ACCESSO: {
    value: StatoUtenze.PRIMO_ACCESSO,
    label: 'Abilitazione non richiesta',
  },
};

export const ApprovazioneList = [
  StatoUtenze.ATTESA,
  StatoUtenze.NON_APPROVATO,
  StatoUtenze.NON_RICHIESTA,
  StatoUtenze.NON_APPROVABILE,
  StatoUtenze.PRIMO_ACCESSO,
];

export const InseriteList = [
  StatoUtenze.APPROVATO,
  StatoUtenze.APPROVATO_AUTOMATICO,
];

const ProfiliPrivatiRegistazione = [
  // Profili.DIP_AZIENDA_ESTERO,
  Profili.GIORNALISTA,
  Profili.PROF_STUDENTE,
  Profili.ALTRO,
];
const ProfiliPrivati = [
  Profili.CONS_LIB_PROF_NO_COD_MICH,
  Profili.REF_ASS_NO_COD_MICH,
  Profili.DIP_AZIENDA_ESTERO,
  Profili.GIORNALISTA,
  Profili.PROF_STUDENTE,
  Profili.ALTRO,
];
const ProfiliNoBp = [
  Profili.GIORNALISTA,
  Profili.PROF_STUDENTE,
  Profili.ALTRO,
];
const ProfiliEdu = [
  Profili.DIP_AZIENDA,
  Profili.DIP_CREDITO,
  Profili.BROKER,
  Profili.CONS_LIB_PROF,
  Profili.DIP_PA,
  Profili.REF_ASS,
];
const ProfiliBp = [
  Profili.DIP_AZIENDA,
  Profili.DIP_CREDITO,
  Profili.BROKER,
  Profili.CONS_LIB_PROF,
  Profili.DIP_PA,
  Profili.REF_ASS,
  Profili.DIP_AZIENDA_ESTERO,
];
const ProfiliIbridi = [
  Profili.CONS_LIB_PROF,
  Profili.REF_ASS,
];

const ProfiliConRuolo = [
  Profili.DIP_AZIENDA,
  Profili.DIP_CREDITO,
  Profili.BROKER,
  Profili.CONS_LIB_PROF,
  Profili.DIP_PA,
  Profili.REF_ASS,
  Profili.DIP_AZIENDA_ESTERO,
];

export const GestioneProfili = {
  PrivatiRegistazione: ProfiliPrivatiRegistazione,
  Privati: ProfiliPrivati,
  Ibridi: ProfiliIbridi,
  ConRuolo: ProfiliConRuolo,
  NoBp: ProfiliNoBp,
  Profili,
  ProfiliBp,
  ProfiliEdu,
};
