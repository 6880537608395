<!-- eslint-disable max-len -->
<template>
  <header
    id="mainHeader"
    class="light"
  >
    <div class="mobile-sticky d-lg-none">
      <img src="/assets/images/logo-sace-2023.svg">
      <div
        class="hamburger"
        @click="toggleNav"
      >
        <div class="hamburger-box">
          <div class="hamburger-inner" />
        </div>
      </div>
    </div>
    <section>
      <b-container fluid>
        <b-row>
          <b-col
            cols="12"
            order-lg="2"
          >
            <nav>
              <h1>Sace</h1>
              <ul
                v-if="user.loaded"
                class="nav"
              >
                <li>
                  <button @click="gotoUrl('/private/dashboard')">
                    <span>MySACE</span>
                  </button>
                </li>
                <li v-if="productsEnabled.platformsEnabled.esg">
                  <button @click="gotoUrl('/private/platform/esg')">
                    <span>ESG Hub</span>
                  </button>
                </li>
                <li
                  v-if="enabledProdotti"
                  class="has-subnav"
                >
                  <button @click="toggleSubnav">
                    <span>Prodotti</span>
                  </button>
                  <ul class="subnav">
                    <li class="title">
                      <h2>Prodotti</h2>
                    </li>
                    <li v-if="productsEnabled.assicurazioneEnabled">
                      <button @click="toggleDescription">
                        Assicurazioni
                      </button>
                      <div class="description">
                        <p>
                          L’<strong>assicurazione del credito</strong> digitale per le aziende che
                          esportano beni o servizi e vogliono offrire dilazioni di pagamento
                          vantaggiose ai propri clienti, coprendosi dai rischi di mancato
                          pagamento.<br>
                          Ti assicuriamo anche il
                          <strong>mancato recupero dei costi di produzione</strong>, che si verifica
                          qualora l'esecuzione del contratto sia interrotta a causa di eventi di
                          natura commerciale o politica.
                        </p>
                        <b-row>
                          <b-col lg="5">
                            <a
                              class="button block primary ellipsis"
                              :href="prodottiUrl.assicurazioni.new"
                            >
                              Richiedi ora
                            </a>
                          </b-col>
                          <b-col lg="5">
                            <a
                              class="button block secondary alternate ellipsis"
                              :href="prodottiUrl.assicurazioni.dashboard"
                            >
                              Vai alle tue assicurazioni
                            </a>
                          </b-col>
                        </b-row>
                      </div>
                    </li>
                    <li v-if="productsEnabled.vaEnabled">
                      <button @click="toggleDescription">
                        Valutazione azienda
                      </button>
                      <div class="description">
                        <p>
                          <strong>Valutazione Azienda</strong> ti permette di conoscere la capacità
                          dei tuoi partner commerciali italiani o esteri di far fronte ai propri
                          <strong>impegni di pagamento</strong>, grazie all'attenta analisi del
                          nostro team, espressa con l’importo di
                          <strong>credito massimo consigliato</strong> e un
                          <strong>indice di affidabilità</strong>.<br>
                          Inserisci i dati della tua controparte italiana o estera e ottieni in
                          pochi passaggi una valutazione di affidabilità.
                        </p>
                        <b-row>
                          <b-col lg="5">
                            <a
                              class="button block primary ellipsis"
                              :href="prodottiUrl.valutaizioniAzienda.new"
                            >
                              Richiedi ora
                            </a>
                          </b-col>
                          <b-col lg="5">
                            <a
                              class="button block secondary alternate ellipsis"
                              :href="prodottiUrl.valutaizioniAzienda.dashboard"
                            >
                              Vai alle tue valutazioni
                            </a>
                          </b-col>
                        </b-row>
                      </div>
                    </li>
                    <li v-if="productsEnabled.ccdAbbonamentoEnabled">
                      <button @click="toggleDescription">
                        Check banche estere in un click
                      </button>
                      <div class="description">
                        <p>
                          <strong>Check banche estere in un click</strong> è la soluzione che permette alle aziende di consultare la lista delle banche estere, e verificare in tempo reale se la banca proposta dal proprio buyer rientra tra quelle affidabili, ed eventualmente richiedere un’assicurazione Credito Fornitore su rischio banca, oppure una conferma di credito documentario.
                          Puoi attivare subito il tuo abbonamento e avrai la possibilità di consultare la lista banche pre-affidate gratuitamente per 18 mesi.
                        </p>
                        <b-row>
                          <b-col lg="5">
                            <a
                              class="button block primary ellipsis"
                              :href="consultazionePlafond.abbonamentoAttivo ? consultazionePlafond.nuovaRicerca : consultazionePlafond.attivaAbbonamento"
                            >
                              {{ consultazionePlafond.labelCta }}
                            </a>
                          </b-col>
                        </b-row>
                      </div>
                    </li>
                    <li v-if="productsEnabled.protezioneClimaSmartEnabled">
                      <button @click="toggleDescription">
                        Rischio clima SMART
                      </button>
                      <div class="description">
                        <p>
                          <strong>Rischio clima SMART</strong> Con noi puoi coprire fino all'importo di 200.000€ i danni ai beni - fabbricati, impianti e macchinari, attrezzature industriali e commerciali - causati dalle seguenti calamità naturali ed eventi catastrofali:  sismi, alluvioni, esondazioni ed inondazioni, franamento.
                        </p>
                        <b-row>
                          <b-col lg="5">
                            <a
                              class="button block primary ellipsis"
                              :href="prodottiUrl.protezioneClima.new"
                            >
                              Richiedi ora
                            </a>
                          </b-col>
                          <b-col lg="5">
                            <a
                              class="button block secondary alternate ellipsis"
                              :href="prodottiUrl.protezioneClima.dashboard"
                            >
                              Vai alle tue richieste
                            </a>
                          </b-col>
                        </b-row>
                      </div>
                    </li>
                    <li v-if="productsEnabled.cauzioneEnabled">
                      <button @click="toggleDescription">
                        Cauzioni
                      </button>
                      <div class="description">
                        <p>
                          Con noi puoi partecipare facilmente a
                          <strong>gare internazionali</strong> e ottenere le
                          <strong>garanzie contrattuali richieste dai committenti</strong>. Le
                          nostre cauzioni sono accettate da beneficiari pubblici e privati
                          permettono di <strong>non immobilizzare denaro</strong> o altre garanzie
                          reali e
                          <strong>liberare risorse finanziarie per sostenere la crescita
                            aziendale</strong>.<br>
                          Richiedi online tutte le nostre cauzioni: appalti,edilizia, ambientali,
                          rimborso iva, caro energia e altra garanzie.
                        </p>
                        <b-row>
                          <b-col lg="5">
                            <a
                              class="button block primary ellipsis"
                              :href="prodottiUrl.cauzioni.new"
                            >
                              Richiedi ora
                            </a>
                          </b-col>
                          <b-col lg="5">
                            <a
                              class="button block secondary alternate ellipsis"
                              :href="prodottiUrl.cauzioni.dashboard"
                            >
                              Vai alle tue cauzioni
                            </a>
                          </b-col>
                        </b-row>
                      </div>
                    </li>
                    <li v-if="productsEnabled.richiestaIndennizzoEnabled">
                      <button @click="toggleDescription">
                        Richiesta di indennizzo
                      </button>
                      <div class="description">
                        <p>
                          <strong>Richiesta di indennizzo</strong>
                        </p>
                        <b-row>
                          <b-col lg="5">
                            <a
                              class="button block primary ellipsis"
                              :href="prodottiUrl.richiestaIndennizzo.new"
                            >
                              Richiedi ora
                            </a>
                          </b-col>
                          <b-col lg="5">
                            <a
                              class="button block secondary alternate ellipsis"
                              :href="prodottiUrl.richiestaIndennizzo.dashboard"
                            >
                              Vai alle tue richieste di indennizzo
                            </a>
                          </b-col>
                        </b-row>
                      </div>
                    </li>
                    <li v-if="productsEnabled.catNatEnabled">
                      <button @click="toggleDescription">
                        Catastrofe Naturale
                      </button>
                      <div class="description">
                        <p>
                          <strong>Catastrofe Naturale</strong>
                        </p>
                        <b-row>
                          <b-col lg="5">
                            <a
                              class="button block primary ellipsis"
                              :href="prodottiUrl.catNat.new"
                            >
                              Richiedi ora
                            </a>
                          </b-col>
                        </b-row>
                      </div>
                    </li>
                  </ul>
                </li>
                <li
                  v-if="enabledServizi"
                  class="has-subnav"
                >
                  <button @click="toggleSubnav">
                    <span>Servizi</span>
                  </button>
                  <ul class="subnav">
                    <li class="title">
                      <h2>Servizi</h2>
                    </li>
                    <li v-if="productsEnabled.serviziEnabled.calendarioEventi">
                      <button @click="toggleDescription">
                        Calendario eventi
                      </button>
                      <div class="description">
                        <p>
                          Consulta gratuitamente il nostro calendario eventi, potrai trovare
                          l’elenco aggiornato di tutti i nostri <strong>convegni</strong>,
                          <strong>seminari</strong> e <strong>workshop</strong>.<br>
                          Disponibile per te, insieme all’elenco, la possibilità di iscriverti per
                          partecipare subito al prossimo evento.
                        </p>
                        <b-row>
                          <b-col lg="5">
                            <a
                              class="button block primary ellipsis"
                              href="/private/servizi/lite/calendario-eventi"
                            >
                              Scopri
                            </a>
                          </b-col>
                        </b-row>
                      </div>
                    </li>
                    <li v-if="productsEnabled.serviziEnabled.sanzioni">
                      <button @click="toggleDescription">
                        Mappa dei paesi sanzionati
                      </button>
                      <div class="description">
                        <p>
                          Consulta gratuitamente la nostra Mappa Sanzioni, in cui è disponibile l’
                          <strong>elenco aggiornato di tutti i Paesi sottoposti a misure restrittive da
                            parte dell’Unione Europea e/o dagli Stati Uniti d’America</strong>, con il dettaglio delle sanzioni economiche internazionali soggettive
                          e/o oggettive adottate per ciascun Paese Sanzionato.
                        </p>
                        <b-row>
                          <b-col lg="5">
                            <a
                              class="button block primary ellipsis"
                              href="/private/servizi/lite/mappa-sanzioni"
                            >
                              Scopri
                            </a>
                          </b-col>
                        </b-row>
                      </div>
                    </li>
                    <li v-if="productsEnabled.serviziEnabled.pta">
                      <button @click="toggleDescription">
                        Pricing Tool Avanzato
                      </button>
                      <div class="description">
                        <p>
                          Inserisci alcuni dati dell'operazione e della tua controparte e ottieni
                          subito una <strong>stima gratuita del prezzo</strong>.
                        </p>
                        <b-row>
                          <b-col lg="5">
                            <a
                              class="button block primary ellipsis"
                              href="/private/servizi/lite/PtaAssicurazione"
                            >
                              Scopri
                            </a>
                          </b-col>
                        </b-row>
                      </div>
                    </li>
                    <li v-if="productsEnabled.serviziEnabled.tem">
                      <button @click="toggleDescription">
                        Trova il tuo TEM
                      </button>
                      <div class="description">
                        <p>
                          Consulta gratuitamente l’<strong>elenco di aziende che erogano servizi di Temporary Export
                            Management</strong>
                          (TEM) per il supporto allo sviluppo internazionale del tuo business.
                          Inserisci nel configuratore di ricerca le caratteristiche per te più
                          rilevanti e scopri il TEM più adatto alle tue esigenze. Disponibile per
                          te, insieme all’elenco delle aziende TEM, la possibilità di consultare una
                          scheda di dettaglio approfondita su un'azienda di tuo interesse. Inizia
                          subito la tua ricerca!
                        </p>
                        <b-row>
                          <b-col lg="5">
                            <a
                              class="button block primary ellipsis"
                              href="/private/servizi/lite/tem"
                            >
                              Scopri
                            </a>
                          </b-col>
                        </b-row>
                      </div>
                    </li>
                    <li v-if="productsEnabled.programmiEnabled.saceEducation">
                      <button @click="toggleDescription">
                        L'esperto risponde
                      </button>
                      <div class="description">
                        <p>
                          L’Esperto Risponde è lo strumento ideato
                          <strong>per risolvere i tuoi dubbi e rispondere a tutti i quesiti</strong>
                          in tema di procedure doganali, transizione ecologica e digitale, prodotti
                          finanziari e assicurazione del credito. Cosa aspetti? Cerca subito la
                          risposta utile per te o invia ai nostri esperti la tua domanda!
                        </p>
                        <b-row>
                          <b-col lg="5">
                            <a
                              class="button block primary ellipsis"
                              href="/private/servizi/lite/esperto-risponde"
                            >
                              Scopri
                            </a>
                          </b-col>
                        </b-row>
                      </div>
                    </li>
                    <li v-if="productsEnabled.serviziEnabled.consulenzaPersonalizzata">
                      <button @click="toggleDescription">
                        Consulenza personalizzata
                      </button>
                      <div class="description">
                        <p>
                          Seleziona l'esigenza che hai in questo momento e
                          <strong>ti metteremo in contatto con il tuo Solution Expert</strong> che
                          potrà supportarti al meglio nel conoscere i nostri prodotti e servizi
                          utili a soddisfare i tuoi bisogni.
                        </p>
                        <b-row>
                          <b-col lg="5">
                            <a
                              class="button block primary ellipsis"
                              href="/private/servizi/lite/consulenza-personalizzata"
                            >
                              Scopri
                            </a>
                          </b-col>
                        </b-row>
                      </div>
                    </li>
                    <li v-if="productsEnabled.serviziEnabled.sanctionsGPS">
                      <button @click="toggleDescription">
                        Sanctions GPS
                      </button>
                      <div class="description">
                        <p>
                          Verifica gratuitamente la presenza di <strong>sanzioni economiche internazionali</strong> imposte dall’Unione Europea o dagli Stati Uniti d’America a carico di <strong>controparti estere</strong>, siano esse persone fisiche o giuridiche
                        </p>
                        <b-row>
                          <b-col lg="5">
                            <a
                              class="button block primary ellipsis"
                              href="/private/servizi/lite/sanctionsGPS"
                            >
                              Scopri
                            </a>
                          </b-col>
                        </b-row>
                      </div>
                    </li>
                  </ul>
                </li>
                <li
                  v-if="enabledProgrammi"
                  class="has-subnav"
                >
                  <button @click="toggleSubnav">
                    <span>Programmi</span>
                  </button>
                  <ul class="subnav">
                    <li class="title">
                      <h2>Programmi</h2>
                    </li>
                    <li v-if="productsEnabled.programmiEnabled.businessPromotion">
                      <button @click="toggleDescription">
                        SACE Connects
                      </button>
                      <div class="description">
                        <p>
                          Iscrivendoti al programma la tua azienda avrà l’opportunità esclusiva di essere invitata ad eventi
                          e incontri di business con Buyer esteri clienti e/o partner di SACE,
                          selezionare le controparti di interesse e candidarsi ad incontrarle.
                        </p>
                        <b-row>
                          <b-col lg="5">
                            <a
                              class="button block primary ellipsis"
                              href="/private/programmi/lite/sace-connects"
                            >
                              Scopri
                            </a>
                          </b-col>
                        </b-row>
                      </div>
                    </li>
                    <li v-if="productsEnabled.programmiEnabled.saceEducation">
                      <button @click="toggleDescription">
                        SACE Education
                      </button>
                      <div class="description">
                        <p>
                          L’offerta formativa di SACE si sviluppa su tre direttrici per soddisfare
                          le differenti esigenze delle imprese: ai percorsi e agli incontri
                          specialistici sul territorio in tema di
                          <strong>export e internazionalizzazione</strong>, si aggiungono nuovi
                          programmi di accompagnamento verso le <strong>strategie green</strong>,
                          digitali e infrastrutturali e la
                          <strong>formazione accademica</strong> certificata.
                        </p>
                        <b-row>
                          <b-col lg="5">
                            <a
                              class="button block primary ellipsis"
                              href="/private/programmi/lite/sace-education"
                            >
                              Scopri
                            </a>
                          </b-col>
                        </b-row>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="has-subnav">
                  <button @click="toggleSubnav">
                    <span>Profilo</span>
                  </button>
                  <ul class="subnav">
                    <li class="title">
                      <h2>Profilo</h2>
                    </li>
                    <li>
                      <button @click="toggleDescription">
                        I tuoi dati
                      </button>
                      <div class="description">
                        <p>
                          In questa sezione puoi visualizzare la tua anagrafica, modificare alcune
                          informazioni e consultare la documentazione relativa alla privacy.
                        </p>
                        <b-row>
                          <b-col lg="5">
                            <a
                              class="button block primary ellipsis"
                              href="/private/profile"
                            >
                              Scopri
                            </a>
                          </b-col>
                        </b-row>
                      </div>
                    </li>
                    <li v-if="gestioneUtenzeEnabled">
                      <button @click="toggleDescription">
                        Gestione Utenze
                      </button>
                      <div class="description">
                        <p>
                          In questa sezione puoi visualizzare le utenze già associate alla tua
                          azienda e approvare/rifiutare nuovi colleghi ad operare per nome e per
                          conto delle tua azienda.
                        </p>
                        <b-row>
                          <b-col lg="5">
                            <a
                              class="button block primary ellipsis"
                              href="/private/gestioneUtenze"
                            >
                              Scopri
                            </a>
                          </b-col>
                        </b-row>
                      </div>
                    </li>
                    <li v-if="productsEnabled.programmiEnabled.saceEducation">
                      <button @click="toggleDescription">
                        Contenuti preferiti
                      </button>
                      <div class="description">
                        <p>
                          In questa sezione puoi salvare i contenuti di tuo interesse per averli
                          sempre disponibili. Questa funzionalità per il momento è disponibile per i
                          contenuti di SACE Education.
                        </p>
                        <b-row>
                          <b-col lg="5">
                            <a
                              class="button block primary ellipsis"
                              href="/private/contenuti-preferiti"
                            >
                              Scopri
                            </a>
                          </b-col>
                        </b-row>
                      </div>
                    </li>
                    <li v-if="user.platforms.esgPartner">
                      <button @click="toggleDescription">
                        Partner ESG Hub
                      </button>
                      <div class="description">
                        <p>
                          In questa sezione puoi visualizzare le richieste di contatto ricevute dalle
                          aziende all'interno del SACE ESG Hub e aggiornare il registro attività.
                        </p>
                        <b-row>
                          <b-col lg="5">
                            <a
                              class="button block primary ellipsis"
                              href="/private/profile/esg-partner"
                            >
                              Scopri
                            </a>
                          </b-col>
                        </b-row>
                      </div>
                    </li>
                    <li>
                      <button @click="toggleDescription">
                        Logout
                      </button>
                      <div class="description">
                        <p>
                          Esci dall’area riservata del Gruppo SACE.
                        </p>
                        <b-row>
                          <b-col lg="5">
                            <button
                              class="button block primary ellipsis"
                              @click="logout"
                            >
                              Logout
                            </button>
                          </b-col>
                        </b-row>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
          </b-col>
          <b-col
            cols="12"
            order-lg="1"
          >
            <aside>
              <b-row>
                <b-col
                  lg="6"
                  order-lg="2"
                >
                  <ul>
                    <li
                      :class="['pointer', { new: notifications.number }]"
                      @click="toggleNotifications"
                    >
                      <i class="bell" />
                      Notifiche
                    </li>
                    <li>
                      <i class="user" />
                      Ciao {{ user.personali.nome }}
                    </li>
                  </ul>
                </b-col>
                <b-col
                  lg="6"
                  order-lg="1"
                >
                  <a :href="saceLogin">
                    Vai su www.SACE.it
                  </a>
                </b-col>
              </b-row>
            </aside>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <div
      v-if="notifications.modal"
      class="notification"
    >
      <div class="notifications-wrapper">
        <h2>Notifiche</h2>
        <button
          class="close"
          @click="toggleNotifications"
        />
        <ul v-if="notifs.length > 0">
          <li
            v-for="(n, index) in notifs.slice(0, 6)"
            :key="index"
            :class="[{ disabled: !n.linkUrl }, { new: n.linkable && !n.alreadyRead }]"
          >
            <a
              :href="n.linkable && n.linkUrl"
              @click="clicked(n, index)"
            >
              <span class="description">{{ n.text }}</span>
              <span class="date">{{ n.notificationDate | formatDate }}</span>
            </a>
          </li>
        </ul>
        <p
          v-else
          class="text-center text-medium"
        >
          Non sono presenti nuove notifiche
        </p>
        <a
          class="read-more"
          href="/private/notification"
        >Tutte le notifiche</a>
      </div>
    </div>
  </header>
</template>

<script>
import Vue from 'vue';
import { BootstrapVue } from 'bootstrap-vue';
import moment from 'moment';
import 'moment/locale/it';
import { Paths } from '../common/paths';
import { CauzioniPaths } from '../common/paths/cauzioni';
import { AssicurazioniPaths } from '../common/paths/assicurazioni';
import { RichiesteIndennizzo } from '../common/paths/richiesteIndennizzo';

import { MixinPagineBase } from '../common/MixinPagineBase';
import { GestioneProfili, InseriteList } from '../common/utenze';

Vue.use(BootstrapVue);

export default {
  filters: {
    formatDate(value) {
      return value ? moment(value).format('DD.MM.YYYY') : '';
    },
  },
  mixins: [MixinPagineBase],
  props: {
    user: {
      type: Object,
      default() {
        return {};
      },
    },
    productsEnabled: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      notifs: [],
      notifications: {
        number: 0,
        modal: false,
      },
      abbonamentoAttivo: false,
    };
  },
  computed: {
    consultazionePlafond() {
      return {
        abbonamentoAttivo: this.abbonamentoAttivo,
        nuovaRicerca: '/private/prodotti/consultazione-plafond/ricerca',
        attivaAbbonamento: '/private/prodotti/consultazione-plafond',
        labelCta: this.abbonamentoAttivo ? 'Nuova Ricerca' : 'Attiva Abbonamento',
        textBox: this.abbonamentoAttivo ? 'È attivo l\'abbonamento per consultare la lista delle banche pre-affidate ' : 'Non hai ancora attivato l\'abbonamento per consultare la lista di banche estere pre-affidate',
      };
    },
    gestioneUtenzeEnabled() {
      return (
        !GestioneProfili.Privati.includes(this.user.profilo.idProfilo) &&
        InseriteList.includes(this.user.account.approvazione)
      );
    },
    enabledProdotti() {
      return (
        this.productsEnabled.vaEnabled ||
        this.productsEnabled.assicurazioneEnabled ||
        this.productsEnabled.cauzioneEnabled ||
        this.productsEnabled.richiestaIndennizzoEnabled ||
        this.productsEnabled.catNatEnabled ||
        this.productsEnabled.protezioneClimaSmartEnabled
      );
    },
    enabledProgrammi() {
      return (
        this.productsEnabled.programmiEnabled.businessPromotion ||
        this.productsEnabled.programmiEnabled.saceEducation
      );
    },
    enabledServizi() {
      return (
        this.productsEnabled.serviziEnabled.sanzioni ||
        this.productsEnabled.serviziEnabled.calendarioEventi ||
        this.productsEnabled.serviziEnabled.pta ||
        this.productsEnabled.serviziEnabled.tem
      );
    },
    enabledPlatforms() {
      return (
        this.productsEnabled.platformsEnabled.esg
      );
    },
    prodottiUrl() {
      return {
        cauzioni: {
          new: `${CauzioniPaths.base}/start`,
          dashboard: `${CauzioniPaths.base}/goToVediTutte`,
        },
        assicurazioni: {
          new: AssicurazioniPaths.preventivo,
          dashboard: AssicurazioniPaths.dashboard,
        },
        valutaizioniAzienda: {
          new: '/private/prodotti/va/riskfree/nuovo-acquisto',
          dashboard: '/private/prodotti/va/RiepilogoValutazioni',
        },
        richiestaIndennizzo: {
          new: RichiesteIndennizzo.base,
          dashboard: `${RichiesteIndennizzo.base}/dashboard`,
        },
        catNat: {
          new: '/private/prodotti/cat-nat',
        },
        protezioneClima: {
          new: '/private/prodotti/protezione-clima',
          dashboard: '/private/prodotti/protezione-clima/dashboard',
        },
      };
    },
    userLogged() {
      return this.user.personali.email != null;
    },
    saceLogin() {
      const privateDomain = window.location.hostname;
      const publicDomain =
        privateDomain === 'mysace.it' ? 'www.sace.it' : 'sacesimestcoll.isace.it/';
      return `https://${publicDomain}/Login/LoginExternalProvider/AD B2C SACE COLLAUDO/?ReturnUrl=https%3A%2F%2F${publicDomain}%2F`;
    },
  },
  watch: {
    'productsEnabled.ccdAbbonamentoEnabled': function (val) {
      if (val == true) {
        this.checkAbbonamento();
      }
    },
    async userLogged(value) {
      if (value) {
        // RECO
        if (this.$root.setUserInfo) {
          this.$root.setUserInfo.email = this.user.personali.email;
        }
        await this.getNotifications();
        const badgeContainer = document.getElementById('badge-notification-container');
        if (badgeContainer) {
          badgeContainer.style.removeProperty('display');
        }
      }
    },
  },
  mounted() {
    document.addEventListener('click', event => {
      const subnav = document.querySelector('.has-subnav.open');
      const isClickInside = subnav && subnav.contains(event.target);
      if (!isClickInside && subnav) {
        subnav.classList.remove('open');
      }
    });
  },
  methods: {
    checkAbbonamento() {
      this.$http
        .get(Paths.ccdAbbonamento.checkAbbonamento)
        .then(response => {
          this.setLoading(false);
          this.abbonamentoAttivo = response.data.abbonato;
        })
        .catch(error => {
          this.setLoading(false);
          console.error(error.message);
        });
    },
    isMobile() {
      return document.documentElement.clientWidth < 992;
    },
    closeAllSubnavs() {
      const subnavs = document.querySelectorAll('.has-subnav');
      Array.prototype.map.call(subnavs, element => {
        element.classList.remove('open');
      });
    },
    closeAllDescriptions() {
      const subnavs = document.querySelectorAll('.has-subnav li');
      Array.prototype.map.call(subnavs, element => {
        element.classList.remove('open');
      });
    },
    setSubnavHeight(element) {
      const subnav = element.closest('.subnav');
      const description = element.nextElementSibling;
      subnav.removeAttribute('style');
      const height =
        subnav.offsetHeight < description.offsetHeight
          ? description.offsetHeight
          : subnav.offsetHeight;
      subnav.style.height = height ? `${height}px` : null;
    },
    toggleNav(event) {
      event.target.closest('.hamburger').classList.toggle('close');
      document.getElementById('mainHeader').classList.toggle('active');
      this.closeAllSubnavs();
      this.closeAllDescriptions();
    },
    toggleSubnav(event) {
      const element = event.target.closest('.has-subnav');
      if (!element.classList.contains('open')) {
        this.closeAllSubnavs();
        this.closeAllDescriptions();
      }
      if (!this.isMobile()) {
        const childs = element.querySelectorAll('li:not(.title)');
        childs[0].classList.add('open');
        setTimeout(() => this.setSubnavHeight(childs[0].children[0]), 5);
      }
      element.classList.toggle('open');
    },
    toggleDescription(event) {
      const element = event.target.closest('li');
      if (!element.classList.contains('open')) {
        this.closeAllDescriptions();
      }
      element.classList.toggle('open');
      this.setSubnavHeight(event.target);
    },
    toggleNotifications() {
      this.notifications.modal = !this.notifications.modal;
      document.body.classList.toggle('modal-open');
    },
    getNotifications() {
      return this.$http
        .get('/private/notification/findAll')
        .then(response => {
          const { notifications } = response.data;
          for (let i = 0; i < notifications.length; i += 1) {
            this.notifs.push(notifications[i]);
          }
          for (let i = 0; i < this.notifs.length; i += 1) {
            if (!this.notifs[i].previewRead) {
              this.notifications.number += 1;
            }
          }
          this.notifs.sort((a, b) => b.id - a.id);
        })
        .catch(error => {
          console.error(error);
        });
    },
    gotoUrl(url) {
      window.location.href = url;
    },
    logout() {
      this.gotoUrl('/logout');
      return clearChatSession();
    },
  },
};
</script>
