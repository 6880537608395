/* eslint-disable object-shorthand */
/* eslint-disable func-names */
/* eslint-disable import/prefer-default-export */
import { DocumentsUploadRequestParams } from "../Components/bean/DocumentsUploadRequestParams.js";
import moment from "moment";
import "moment/locale/it";

Array.prototype.containsKey = function(keyName, keyValue) {
  var i,
    l = this.length;
  for (i = 0; i < l; i++) {
    if (this[i][keyName] == keyValue) return true;
  }
  return false;
};

Number.prototype.replace = function() {
  return this.toString().replace();
};

Number.prototype.between = function(min, max) {
  return this >= min && this <= max;
};

const eventSetLoading = "eventSetLoading";

export const Utils = {
  formPost: function(path, params, method) {
    method = method || "post"; //default post

    var form = document.createElement("form");
    form.setAttribute("method", method);
    form.setAttribute("action", path);

    for (var key in params) {
      if (params.hasOwnProperty(key)) {
        var hiddenField = document.createElement("input");
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("name", key);
        hiddenField.setAttribute("value", params[key]);

        form.appendChild(hiddenField);
      }
    }

    document.body.appendChild(form);
    form.submit();
  },

  /**
   * add run event method
   */

  runCustomEvent: function(eventName, params) {
    try {
      var evt = document.createEvent("Event");
      evt.initEvent(eventName, true, true);
      evt["objParams"] = params;
      document.dispatchEvent(evt);
    } catch (e) {
      console.error(e);
    }
  },
  /**
   * add listen event method
   */
  catchCustomEvent: function(eventName, callbackEvent) {
    try {
      document.addEventListener(
        eventName,
        function(event) {
          callbackEvent(event.objParams);
        },
        true
      );
    } catch (e) {
      console.error(e);
    }
  },

  catchEventSetLoading: function(callbackEvent) {
    return this.catchCustomEvent(eventSetLoading, callbackEvent);
  },

  base64toBlob: function(base64Data, contentType) {
    contentType = contentType || "";
    var sliceSize = 1024;
    var byteCharacters = atob(base64Data);
    //var byteCharacters = decodeURIComponent(escape(window.atob(base64Data)))
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      var begin = sliceIndex * sliceSize;
      var end = Math.min(begin + sliceSize, bytesLength);

      var bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  },

  downloadFileFromBinary(fileName, binary) {
    // IE
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      //const blob = new Blob([ atob(binary) ], { type: 'text/csv' });
      const blob = this.base64toBlob(binary, { type: "application/*" });
      window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
      var contentType = "application/octet-stream";
      var dataUrl = "data:" + contentType + ";base64," + binary;

      const link = document.createElement("a");
      link.href = dataUrl;
      link.setAttribute("download", fileName); //or any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  },
  downloadFileFromBinaryWithBlob(fileName, binary) {
    // IE
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      //const blob = new Blob([ atob(binary) ], { type: 'text/csv' });
      const blob = this.base64toBlob(binary, "application/*");
      window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
      var contentType = "application/octet-stream";
      const blob = this.base64toBlob(binary, contentType);
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", fileName); //or any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();
      try {
        //consigliato per gestione memoria https://developer.mozilla.org/en-US/docs/Web/API/URL/createObjectURL
        window.URL.revokeObjectURL(blobUrl);
      } catch (error) {}
    }
  },
  downloadBlob: function(blobData, fileName) {
    const blob = new Blob([blobData], { type: "application/*" });
    // IE
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, fileName);
      return;
    }
    // Altri browser
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
    try {
      //consigliato per gestione memoria https://developer.mozilla.org/en-US/docs/Web/API/URL/createObjectURL
      window.URL.revokeObjectURL(url);
    } catch (error) {}
  },
  /**
   * gestore campi number remove negative inputs
   */
  removeNegativeInput(event) {
    event.target.value = event.target.value.replace(/-/g, "");
  },
  /**
   * Accetta solo immissione di numeri e virgola (consigliato su keydown)
   */
  acceptOnlyNumericAndComma: function(event) {
    // allow function keys and comma
    if (
      $.inArray(event.keyCode, [46, 8, 9, 27, 13, 110, 188]) !== -1 || // backspace, delete, tab, escape, enter, comma
      ($.inArray(event.keyCode, [65, 67, 88]) !== -1 &&
        (event.ctrlKey === true || event.metaKey === true)) || // Ctrl/cmd+A, Ctrl/cmd+C, Ctrl/cmd+X
      (event.keyCode >= 35 && event.keyCode <= 39)
    ) {
      // home, end, left, right
      return;
    }
    // block any non-number
    if (
      (event.shiftKey || event.keyCode < 48 || event.keyCode > 57) &&
      (event.keyCode < 96 || event.keyCode > 105)
    ) {
      event.preventDefault();
    }
  },

  /**
   * Accetta solo immissione di numeri (consigliato su keydown)
   */
  acceptOnlyNumeric: function(event) {
    // allow function keys and comma
    if (
      $.inArray(event.keyCode, [46, 8, 9, 27, 13, 110]) !== -1 || // backspace, delete, tab, escape, enter
      ($.inArray(event.keyCode, [65, 67, 88]) !== -1 &&
        (event.ctrlKey === true || event.metaKey === true)) || // Ctrl/cmd+A, Ctrl/cmd+C, Ctrl/cmd+X
      (event.keyCode >= 35 && event.keyCode <= 39)
    ) {
      // home, end, left, right
      return;
    }
    // block any non-number
    if (
      (event.shiftKey || event.keyCode < 48 || event.keyCode > 57) &&
      (event.keyCode < 96 || event.keyCode > 105)
    ) {
      event.preventDefault();
    }
  },

  /**
   * Accetta solo immissione di numeri (consigliato su keydown)
   */
  acceptOnlyNumeric: function(event) {
    // allow function keys and comma
    if (
      $.inArray(event.keyCode, [46, 8, 9, 27, 13, 110]) !== -1 || // backspace, delete, tab, escape, enter
      ($.inArray(event.keyCode, [65, 67, 88]) !== -1 &&
        (event.ctrlKey === true || event.metaKey === true)) || // Ctrl/cmd+A, Ctrl/cmd+C, Ctrl/cmd+X
      (event.keyCode >= 35 && event.keyCode <= 39)
    ) {
      // home, end, left, right
      return;
    }
    // block any non-number
    if (
      (event.shiftKey || event.keyCode < 48 || event.keyCode > 57) &&
      (event.keyCode < 96 || event.keyCode > 105)
    ) {
      event.preventDefault();
    }
  },
  /**
   * Controlla il corretto formato del testo immesso: da 0,00 a 100,00 e lo converte in float
   */
  percentFormatToNumber: function(event, numOfDecimal) {
    event.target.value = event.target.value.replace(/,+/g, ","); // virgole ripetute rimpiazzate con singola
    event.target.value = event.target.value.replace(/(.)\1{2,}/g, "$1$1"); // massima ripetizione caratteri a 2 es. 111111111 rimpiazzato con 11

    var isValid = /^0*(?:[1-9][0-9]?|100|^0$)$|^\d{0,2}\,\d{0,6}$|100\,\d{0,2}$/.test(
      event.target.value
    );
    if (!isValid || event.target.value === ",") {
      event.target.value = event.target.value.slice(
        0,
        event.target.value.length - 1
      );
    }
    try {
      // keydown sui numeri dopo la virgola es. 11,1111111111 taglia la stringa al formato giusto 11,11
      var commaIndex = event.target.value.indexOf(",");
      if (commaIndex > 0 && event.target.value.length > commaIndex + (numOfDecimal + 1))
        event.target.value = event.target.value.slice(0, commaIndex + (numOfDecimal + 1));
      // controllo di sicurezza sul valore
      var valueFloat = 0;
      if (event.target.value !== "")
        valueFloat = parseFloat(event.target.value.replace(",", "."));
      if (valueFloat > 100) {
        event.target.value = "";
        valueFloat = 0;
      }
    } catch (error) {
      event.target.value = "";
      valueFloat = 0;
    } finally {
      return valueFloat;
    }
  },
  /** Controlla il corretto formato del testo immesso: da 0,00 a 100,00 e lo converte in float
   * 	Se a FE stringa vuota, restituisce null */
  percentFormatToNumberDefaultNull: function(event, numOfDecimal) {
    event.target.value = event.target.value.replace(/,+/g, ","); // virgole ripetute rimpiazzate con singola
    event.target.value = event.target.value.replace(/(.)\1{2,}/g, "$1$1"); // massima ripetizione caratteri a 2 es. 111111111 rimpiazzato con 11

    var isValid = /^0*(?:[1-9][0-9]?|100|^0$)$|^\d{0,2}\,\d{0,6}$|100\,\d{0,2}$/.test(
      event.target.value
    );
    if (!isValid || event.target.value === ",") {
      event.target.value = event.target.value.slice(
        0,
        event.target.value.length - 1
      );
    }
    try {
      // keydown sui numeri dopo la virgola es. 11,1111111111 taglia la stringa al formato giusto 11,11
      var commaIndex = event.target.value.indexOf(",");
      if (commaIndex > 0 && event.target.value.length > commaIndex + (numOfDecimal + 1))
        event.target.value = event.target.value.slice(0, commaIndex +( numOfDecimal + 1));
      // controllo di sicurezza sul valore
      var valueFloat = null;
      if (event.target.value !== "")
        valueFloat = parseFloat(event.target.value.replace(",", "."));
      if (valueFloat > 100) {
        event.target.value = "";
        valueFloat = null;
      }
    } catch (error) {
      event.target.value = "";
      valueFloat = null;
    } finally {
      return valueFloat;
    }
  },
  dotToComma: function(numericVal) {
    if (numericVal !== null && numericVal !== undefined)
      return numericVal.toString().replace(".", ",");
    else return numericVal;
  },
  isNumber: function(evt) {
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46 && charCode !== 44) {
      evt.preventDefault();
    } else {
      return true;
    }
  },
  /**
   * gestore per campi di testo number
   */
  numberField: function() {
    jQuery(".number").keydown(function(e) {
      if (
        (e.keyCode > 46 && e.keyCode < 58) || // numbers
        (e.keyCode > 95 && e.keyCode < 106) || // numpad
        e.keyCode === 8 ||
        e.keyCode === 46 || //backspace and canc
        (e.keyCode > 36 && e.keyCode < 41) || // arrows
        e.keyCode == 9
      ) {
        // tab
      } else {
        e.preventDefault();
      }
    });
  },

  //for select with choices.js plugin
  initChoices: function(id, shouldSort, placeholderValue, autocompleteId) {
    if (Choices) {
      /*var calbackObsChoices = function (mutationsList, observer) {
				for (var mutation of mutationsList) {
					if (mutation.type == 'childList') {
						console.log('A child node has been added or removed.');
						var a = new Choices(mutationsList.pop().target, {
							searchEnabled: !1,
							searchChoices: !1,
							searchFloor: 1,
							itemSelectText: ""
						});
					}
					else if (mutation.type == 'attributes') {
						console.log('The ' + mutation.attributeName + ' attribute was modified.');
					}
				}
			};*/

      //var choicesObj = {};
      if (!window.window.choicesObj) window.choicesObj = {};

      //var config = { attributes: true, childList: true, subtree: true, addItems: true };

      var e = document.getElementsByClassName("custom-select"),
        o = !0,
        t = !1,
        c = void 0;
      try {
        for (
          var n, r = e[Symbol.iterator]();
          !(o = (n = r.next()).done);
          o = !0
        ) {
          var a = n.value;

          if (
            !choicesObj[
              n.value.getAttribute("id") || n.value.getAttribute("id") == id
            ]
          ) {
            let config = {
              searchEnabled: !1,
              searchChoices: !1,
              searchFloor: 1,
              itemSelectText: "",
              shouldSort: shouldSort != null ? shouldSort : true,
              placeholder: true,
              placeholderValue:
                placeholderValue != null ? placeholderValue : null,
            };

            if (
              autocompleteId &&
              autocompleteId == n.value.getAttribute("id")
            ) {
              config.searchEnabled = true;
              config.searchChoices = true;
              config.searchFloor = 1;
              config.searchResultLimit = 50;
              config.searchFields = ["label"];
            }
            a = new Choices(a, config);
            choicesObj[n.value.getAttribute("id")] = a;
            /*var obs = new MutationObserver(calbackObsChoices);
						obs.observe(n.value, config);*/
          }
        }
        return choicesObj;
      } catch (e) {
        (t = !0), (c = e);
      } finally {
        try {
          !o && r.return && r.return();
        } finally {
          if (t) throw c;
        }
      }
    }
  },

  initTinySlider: function() {
    if (null !== document.querySelector(".lgt-project"))
      tns({
        container: ".lgt-project",
        items: 1,
        gutter: 0,
        speed: 500,
        slideBy: "page",
        autoplay: !1,
        controls: !0,
        controlsText: ["", ""],
        arrowKeys: !0,
        navPosition: "bottom",
        loop: !1,
        responsive: {
          768: {
            items: 2,
          },
          1024: {
            items: 3,
          },
          1200: {
            items: 5,
          },
        },
      });
    if (null !== document.querySelector(".lgt__main")) {
      var e = function() {
          var e = this.querySelector(".lgt__image-box").getAttribute(
            "data-imgHover"
          );
          this.querySelector(".lgt__image").src = e;
        },
        o = function() {
          var e = this.querySelector(".lgt__image-box").getAttribute(
            "data-image"
          );
          this.querySelector(".lgt__image").src = e;
        },
        t = document.getElementsByClassName("lgt__main"),
        c = void 0;
      for (c = 0; c < t.length; c++)
        t[c].addEventListener("mouseenter", e, !1),
          t[c].addEventListener("mouseleave", o, !1);
    }
    var n = Array.from(document.querySelectorAll(".lgt__item")),
      r = document.querySelector(".lgt__overlay"),
      a = Array.from(document.querySelectorAll(".lgt__main")),
      i = Array.from(document.querySelectorAll(".lgt__close-cta")),
      l = function(e) {
        var o = e.currentTarget.getAttribute("data-lgt-detail"),
          t = document.getElementById(o);
        (r.querySelector(".lgt__overlay-content").innerHTML = t.innerHTML),
          console.log(o),
          e.preventDefault(),
          n.forEach(function(e) {
            e.parentElement.classList.remove("active-lgt");
          }),
          r.classList.add("lgt-overlay"),
          e.currentTarget.parentElement.classList.add("active-lgt");
      };
    n.forEach(function(e) {
      e.addEventListener("click", l, !1);
    });
    var s = function(e) {
      console.log("click close"),
        e.preventDefault(),
        a.forEach(function(e) {
          e.classList.remove("active-lgt");
        }),
        r.classList.remove("lgt-overlay"),
        (r.querySelector(".lgt__overlay-content").innerHTML = "");
    };
    i.forEach(function(e) {
      e.addEventListener("click", s, !1);
    });
  },

  /** Get the correct configuration row from the array _configurationObj, filtering for _key */
  getPageConfigurations: function(_configurationObj, _key) {
    return _configurationObj.filter(function(elem) {
      if (elem.key == _key) return elem;
    })[0];
  },

  getMap: function() {
    return {
      pdf: "data:application/pdf;base64,",
      doc: "data:application/msword,",
      zip: "data:application/x-zip-compressed;base64,",
    };
  },
  /**
   * l.piciollo
   * funzione di codifica di un file, per il recupero del content
   * @param {*} file
   * @param {*} requestID
   * @param {*} documentType
   */
  getContentFile: function(file) {
    return new Promise((respons, errors) => {
      var self = this;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function() {
        DocumentsUploadRequestParams.documentContent = reader.result.substring(
          reader.result.indexOf(",") + 1,
          reader.result.length
        );
        DocumentsUploadRequestParams.name = file.name;
        respons(DocumentsUploadRequestParams);
      };
      reader.onerror = function(error) {
        const errors = new Object();
        Object.defineProperty(response, "error", {
          value: error,
        });
        errors(errors);
      };
    });
  },
  /**
   * l.piciollo
   * genera un id univoco ad ogni invocazione.
   */
  uuid: function() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  },

  /**utilizzato per parsare la percentuale di fatturato*/
  formatStringToFloat: function(value) {
    if (value != null) {
      return parseFloat(value.replace(",", ".")).toFixed(2);
    } else {
      return null;
    }
  },

  /**
   * l.piciollo
   * download file from href
   */
  downloadFileFromHref(fileName, url) {
    try {
      const link = document.createElement("a");
      link.href = window.location.origin + "/" + url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (e) {
      console.error(e);
    }
  },

  /** Get the array subset of element in _list with key= _key */
  filterArrayForKey: function(_list, _key) {
    return _list.filter(function(elem) {
      if (elem.key == _key) return elem;
    });
  },

  /** Get the array subset of element in _list with key= _key */
  filterArrayForValue: function(_list, _value) {
    return _list.filter(function(elem) {
      if (elem.value == _value) return elem;
    });
  },

  checkNum(v) {
    return v != null && v !== "" && parseFloat(v) != NaN && parseFloat(v) != 0;
  },

  strNotEmpty(s) {
    return s != null && s != "";
  },
  formatDate: function(date, format) {
    if (date != null && date !== "") {
      moment.locale("it");
      if (format) {
        return moment(date).format(format);
      } else {
        return moment(date).format("DD MMMM YYYY");
      }
    } else {
      return "-";
    }
  },
  formatPrice: function(value) {
    let val = "";
    try {
      if (value === "-") return "-";
      value = parseFloat(value).toFixed(2);
      val = new Intl.NumberFormat(["it-IT"], {
        style: "currency",
        currency: "EUR",
      }).format(value);
    } catch (err) {
      return "-";
    }
    return val;
  },
  /**
   *	Valida un input text numerico non facendo scrivere valori non numerici.
   * Da richiamare in questo modo: onkeypress='validateNumericField(event)'
   */
  validateNumericField: (evt) => {
    const theEvent = evt || window.event;
    let key;
    if (theEvent.type === "paste") {
      key = event.clipboardData.getData("text/plain");
    } else {
      const eventCode = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(eventCode);
    }
    const regex = /^[0-9]*$/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  },
  capitalize: function(text) {
    if (text) {
      return `${text.charAt(0).toUpperCase()}${text.slice(1).toLowerCase()}`;
    }
  },
};
