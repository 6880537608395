/**
 * l.piciollo
 * classe di scambio per il passaggio di parametri al BE
 */
export const DocumentsUploadRequestParams = {
  documentContent    : null,
  filetype           : null,
  requestID          : null,
  documentType       : null,
  name               : null 

};
