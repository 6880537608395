var es6Promise = require('es6-promise');
if (!global.Promise) {
	es6Promise.polyfill();
}

export const MixinDynamicFooter = {
	data: function () {
		return {
			saceBtFooterAssic: false,
			saceBtFooterPolizza: false,
			footerValAzienda: false
		}
	},

	computed : {
		
	},
	
	mounted: function () {},
	
	methods: {
		setSaceBtFooterAssic: function (value) {
			this.saceBtFooterAssic = value;
		},
		
		setSaceBtFooterPolizza: function (value) {
			this.saceBtFooterPolizza = value;
		},
		
		setValutazioneAziendaFooter: function (value) {
			if(this.riskfreemodel && this.riskfreemodel.response && this.riskfreemodel.response.serviceDescription == 'Valutazione azienda'){
				this.footerValAzienda = value;
			}
			
			else{
				this.footerValAzienda = false;
			}
		}
	}
};