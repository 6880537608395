const minCredito = 1000;
const maxCredito = 20000000;

export const PtaValidationMsg = Object.freeze({
   datiContratto:{
      minCredito : minCredito,
      maxCredito : maxCredito,
      paese : "Il campo paese è richiesto",
      settore : "Il campo settore è richiesto",
      importoCredito : "Il campo importo deve essere compreso tra " + minCredito + " e " +maxCredito,
      anniClienteAttivo : "Il campo anni di attività è richiesto" ,
      annoRifDatiContabili : "Il campo anno di riferimento è richiesto",
      durataCredito : "Il campo durata del credito è obbligatorio"
   },

   datiBilancio:{
      valuta : "Il campo di valuta del bilancio è richiesto",
		fatturatoCliente : "",
		ebit : "" , //margine operativo cliente
		patrimonioNettoCliente : "",
		totaleDebitiFinanziariCliente : "",
		totalePassivoCliente: "",
		utileNettoCliente : ""
   },

   apiError : "Oops! Siamo spiacenti, si è verificato un errore. Qualora il problema dovesse persistere contatta il nostro Customer Care al numero 800.269.264"

});