import moment from 'moment';

moment.locale('it');

/**
 * Dato un timestamp restituisce la data con il formato gg MM aaaa (In italiano)
 * es: 1608764400000 => 24 Dicembre 2020
 * @param {timestamp} date
 */
export function formatDate(date) {
  return date ? moment(date).format('LL') : '';
}

/**
 * Dato un timestamp restituisce la data con il formato gg/MM/aaaa
 * es: 1608764400000 => 24/12/2020
 * @param {timestamp} date
 */
export function formatShortDate(date) {
  return date ? moment(date).format('DD/MM/YYYY') : '';
}

/**
 * Dato un timestamp restituisce la data con il formato gg MM aaaa HH:ss
 * es: 1608764400000 => 24/12/2020 ore 00:00
 * @param {timestamp} date
 */
export function formatFullDate(date) {
  return date ? `${moment(date).format('LL')} ore ${moment(date).format('LT')}` : '';
}
